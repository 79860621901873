var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Gudang"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah ")], 1) : _vm._e(), _vm.allowCreate() ? _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalTambah = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 2349726705),
    model: {
      value: _vm.showModalTambah,
      callback: function callback($$v) {
        _vm.showModalTambah = $$v;
      },
      expression: "showModalTambah"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "pilih"
    }
  }, [_vm._v("Pilih gudang untuk?")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Pusat"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Pusat")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Cabang"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Cabang")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Marketing"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Marketing")]), _c('b-card-text', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v(" Pilihan: "), _c('strong', [_vm._v(_vm._s(_vm.selected))])])], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_gudang"
    }
  }, [_vm._v("Nama Gudang")]), _c('b-form-input', {
    attrs: {
      "id": "gudang",
      "placeholder": "Nama gudang"
    },
    model: {
      value: _vm.form.nama_gudang,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nama_gudang", $$v);
      },
      expression: "form.nama_gudang"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat")]), _c('b-form-textarea', {
    attrs: {
      "id": "alamat",
      "placeholder": "Alamat"
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "id": "keterangan",
      "placeholder": "Keterangan"
    },
    model: {
      value: _vm.form.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1), _vm.selected == 'Pusat' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "admin"
    }
  }, [_vm._v("Admin Gudang Pusat")]), _c('b-form-select', {
    attrs: {
      "options": _vm.karyawan_id,
      "label": "admin"
    },
    model: {
      value: _vm.form.karyawan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "karyawan_id", $$v);
      },
      expression: "form.karyawan_id"
    }
  })], 1) : _vm._e(), _vm.selected == 'Cabang' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "cbg"
    }
  }, [_vm._v("Admin Cabang")]), _c('b-form-select', {
    attrs: {
      "options": _vm.member_id,
      "label": "cbg"
    },
    model: {
      value: _vm.form.member_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "member_id", $$v);
      },
      expression: "form.member_id"
    }
  })], 1) : _vm._e(), _vm.selected == 'Marketing' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "cbg"
    }
  }, [_vm._v("Admin Marketing")]), _c('b-form-select', {
    attrs: {
      "options": _vm.member_id,
      "label": "cbg"
    },
    model: {
      value: _vm.form.member_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "member_id", $$v);
      },
      expression: "form.member_id"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-title": "Ubah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalEdit = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 476204478),
    model: {
      value: _vm.showModalEdit,
      callback: function callback($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "pilih"
    }
  }, [_vm._v("Pilih gudang untuk?")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Pusat"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Pusat")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Cabang"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Cabang")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "Marketing"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v("Marketing")]), _c('b-card-text', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v(" Pilihan: "), _c('strong', [_vm._v(_vm._s(_vm.selected))])])], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_gudang"
    }
  }, [_vm._v("Nama Gudang")]), _c('b-form-input', {
    attrs: {
      "id": "gudang",
      "placeholder": "Nama gudang"
    },
    model: {
      value: _vm.form.nama_gudang,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nama_gudang", $$v);
      },
      expression: "form.nama_gudang"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat")]), _c('b-form-textarea', {
    attrs: {
      "id": "alamat",
      "placeholder": "Alamat"
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "id": "keterangan",
      "placeholder": "Keterangan"
    },
    model: {
      value: _vm.form.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1), _vm.selected == 'Pusat' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "admin"
    }
  }, [_vm._v("Admin Gudang Pusat")]), _c('b-form-select', {
    attrs: {
      "options": _vm.karyawan_id,
      "label": "admin"
    },
    model: {
      value: _vm.form.karyawan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "karyawan_id", $$v);
      },
      expression: "form.karyawan_id"
    }
  })], 1) : _vm._e(), _vm.selected == 'Cabang' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "cbg"
    }
  }, [_vm._v("Admin Cabang")]), _c('b-form-select', {
    attrs: {
      "options": _vm.member_id,
      "label": "cbg"
    },
    model: {
      value: _vm.form.member_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "member_id", $$v);
      },
      expression: "form.member_id"
    }
  })], 1) : _vm._e(), _vm.selected == 'Marketing' ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "cbg"
    }
  }, [_vm._v("Admin Marketing")]), _c('b-form-select', {
    attrs: {
      "options": _vm.member_id,
      "label": "cbg"
    },
    model: {
      value: _vm.form.member_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "member_id", $$v);
      },
      expression: "form.member_id"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(teritory_id)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.getKaryawanName(data.item)) + " ")])];
      }
    }, {
      key: "cell(admin)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.karyawan ? data.item.karyawan.nama_lengkap : data.item.member ? data.item.member.nama_lengkap : '-') + " ")];
      }
    }, {
      key: "cell(sebagai)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.karyawan ? 'Pusat' : data.item.member ? data.item.member.sebagai.toUpperCase() : '-') + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Tambah Kasir',
            expression: "'Tambah Kasir'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }, {
            name: "b-modal",
            rawName: "v-b-modal.modal-kasir",
            modifiers: {
              "modal-kasir": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-primary "
          },
          on: {
            "click": function click($event) {
              return _vm.addkasir(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserPlusIcon"
          }
        }), _vm._v(" Tambah Kasir ")], 1) : _vm._e(), _vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.infoModal.id,
      "title": _vm.infoModal.title,
      "ok-only": ""
    },
    on: {
      "hide": _vm.resetInfoModal
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.infoModal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _vm.allowCreate() ? _c('b-modal', {
    attrs: {
      "id": "modal-kasir",
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Tentukan Kasir",
      "size": "xl"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.kasiradd();
            }
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.modalkasir = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 2207369554),
    model: {
      value: _vm.modalkasir,
      callback: function callback($$v) {
        _vm.modalkasir = $$v;
      },
      expression: "modalkasir"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "8",
      "xl": "8"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Kasir yang sudah terdaftar di Cabang"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptionsKasirs
    },
    model: {
      value: _vm.perPageKasirs,
      callback: function callback($$v) {
        _vm.perPageKasirs = $$v;
      },
      expression: "perPageKasirs"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptionsKasirs
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }], null, false, 478637150),
    model: {
      value: _vm.sortByKasirs,
      callback: function callback($$v) {
        _vm.sortByKasirs = $$v;
      },
      expression: "sortByKasirs"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortByKasirs
    },
    model: {
      value: _vm.sortDescKasirs,
      callback: function callback($$v) {
        _vm.sortDescKasirs = $$v;
      },
      expression: "sortDescKasirs"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Kasir"
    }
  }, [_vm._v("Cari Kasir")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataKasirs();
      }
    },
    model: {
      value: _vm.cariKasir,
      callback: function callback($$v) {
        _vm.cariKasir = $$v;
      },
      expression: "cariKasir"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.getDataKasirs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageKasirs,
      "items": _vm.dataKasirs,
      "fields": _vm.fieldsKasirs,
      "sort-by": _vm.sortByKasirs,
      "sort-desc": _vm.sortDescKasirs,
      "sort-direction": _vm.sortDirectionKasirs,
      "filter-included-fields": _vm.filterOnKasirs
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortByKasirs = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortByKasirs = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDescKasirs = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDescKasirs = $event;
      },
      "filtered": _vm.onFilteredKasirs
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item ? item.kode : '-') + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.kasir ? item.kasir.nama_lengkap : '-') + " ")];
      }
    }, {
      key: "cell(email)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.kasir ? item.kasir.email : '-') + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.removekasir(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 2761462592)
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRowsKasirs,
      "per-page": _vm.perPageKasirs,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPageKasirs,
      callback: function callback($$v) {
        _vm.currentPageKasirs = $$v;
      },
      expression: "currentPageKasirs"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Tentukan Kasir Baru"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_gudang"
    }
  }, [_vm._v("Nama Gudang")]), _c('b-form-input', {
    attrs: {
      "disabled": "",
      "id": "gudang",
      "placeholder": "Nama gudang"
    },
    model: {
      value: _vm.form.nama_gudang,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nama_gudang", $$v);
      },
      expression: "form.nama_gudang"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "admin"
    }
  }, [_vm._v("Pilih Kasir")]), _c('b-form-select', {
    attrs: {
      "options": _vm.karyawankasir_id,
      "label": "admin"
    },
    model: {
      value: _vm.form.karyawan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "karyawan_id", $$v);
      },
      expression: "form.karyawan_id"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }